import {mapActions, mapGetters} from "vuex";

export default {
  name: "contact-page",
  data() {
    return {}
  },
  watch:{
    '$route'(){
      this.fetchPage('contact-page')
    }
  },
  created() {
    this.fetchPage('contact-page')
  },
  computed: {
    ...mapGetters({
      contacts:'setting/contacts',
      page:'pages/page',
    })
  },
  methods: {
    ...mapActions({
      fetchPage: 'pages/GET_PAGE',
    })
  },
}